import { Country, Language, PaymentMethod } from '../models/common';
import i18n from './i18n';

// runtime environment
export const IS_PRODUCTION: boolean = process.env.REACT_APP_STAGE === 'production';
export const IS_DEVELOPMENT: boolean = process.env.REACT_APP_STAGE === 'development' || process.env.REACT_APP_STAGE === undefined;
export const IS_STAGING: boolean = process.env.REACT_APP_STAGE === 'staging';
export const IS_BETA: boolean = process.env.REACT_APP_STAGE === 'beta';

// delay until transition (in ms)
export const TRANSITION_DELAY = 100;
export const FAKE_DELAY: number = 500; /* (Math.random() * 1000 - 250) << 0 */

// transition timeout duration (in ms)
export const TRANSITION_TIMEOUT = 300;
export const PAGE_TRANSITION_TIMEOUT = 500;

// get delay for the N-th element of transition chain
export const getDelay = (index: number): number => Math.max(0, index * TRANSITION_DELAY);

/**
 * TODO: convert to const
 * List of currently supported countries
 * @return {Country[]}
 * @constructor
 */
export const COUNTRIES = (): Country[] => [
	{
		countryId: 1,
		name: i18n.t('countries.CZ.name'),
		stateName: i18n.t('countries.CZ.stateName'),
		isoChar2: 'CZ',
		isoChar3: 'CZE',
		isoNum: 203,
	},
	{
		countryId: 2,
		name: i18n.t('countries.SK.name'),
		stateName: i18n.t('countries.SK.stateName'),
		isoChar2: 'SK',
		isoChar3: 'SVK',
		isoNum: 703,
	},
];

/**
 * TODO: convert to const
 * List of currently supported payment methods
 * @return {PaymentMethod[]}
 * @constructor
 */
export const PAYMENT_METHOD = (): PaymentMethod[] => [
	{
		paymentMethodId: 1,
		name: i18n.t('paymentMethods.onlineByCard'),
	},
	{
		paymentMethodId: 2,
		name: i18n.t('paymentMethods.bankTransfer'),
	},
	{
		paymentMethodId: 3,
		name: i18n.t('paymentMethods.googlePay'),
	},
	{
		paymentMethodId: 4,
		name: i18n.t('paymentMethods.applePay'),
	},
];

/**
 * List of currently supported languages
 * @type {Language[]}
 */
export const LANGUAGES = (): Language[] => [
	{ languageId: 1, name: i18n.t(`lang.cs.title`), code: 'cs' },
	{ languageId: 2, name: i18n.t(`lang.sk.title`), code: 'sk' },
	{ languageId: 3, name: i18n.t(`lang.en.title`), code: 'en' },
	{ languageId: 4, name: i18n.t(`lang.de.title`), code: 'de' },
];

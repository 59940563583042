import moment from 'moment';

// user defined type guard, which guarantees 'object is T', not undefined, not null
// see https://2ality.com/2020/06/type-guards-assertion-functions-typescript.html#user-defined-type-guards
export const isDefined = <T>(object: T | undefined | null): object is T => object !== undefined && object !== null;

export const isNotUndefined = <T>(object: T | undefined): object is T => object !== undefined;

export const isEmpty = <T>(value: T | undefined | null | ''): value is undefined | null | '' => !isDefined(value) || value === '';

/**
 * Return whether the given haystack contains any items from the given needles
 * @param {Array<T>} haystack
 * @param {T} needles
 * @returns {boolean}
 */
export const includesSome = <T>(haystack: Array<T> | undefined | null, ...needles: Array<T>): boolean => {
	return Array.from(needles).some((p) => haystack?.includes(p));
};

/**
 * Return whether the given haystack contains all items from the given needles
 * @param {Array<T>} haystack
 * @param {T} needles
 * @returns {boolean}
 */
export const includesAll = <T>(haystack: Array<T> | undefined | null, ...needles: Array<T>): boolean => {
	return Array.from(needles).every((p) => haystack?.includes(p));
};

/**
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 * @returns Map of the array grouped by the grouping function.
 */
export const groupBy = <K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> => {
	const map = new Map<K, Array<V>>();
	list.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
};

/**
 * Helper to produce an array of enum values.
 * @param enumeration Enumeration object.
 */
export function enumToArray<T>(enumeration: T): NonFunctional<T[keyof T]>[] {
	return Object.keys(enumeration)
		.filter((key) => isNaN(Number(key)))
		.map((key) => (enumeration as any)[key])
		.filter((val) => typeof val === 'number' || typeof val === 'string');
}

type NonFunctional<T> = T extends Function ? never : T;

/**
 * Generic type guard
 * @see https://rangle.io/blog/how-to-use-typescript-type-guards/
 * @param varToBeChecked
 * @param {(keyof T)[]} propertiesToCheckFor
 * @return {varToBeChecked is T}
 */
export const isOfType = <T>(varToBeChecked: any, propertiesToCheckFor?: (keyof T)[]): varToBeChecked is T => {
	if (isEmpty(propertiesToCheckFor)) return isDefined(varToBeChecked);
	return !propertiesToCheckFor.map((propToCheck) => (varToBeChecked as T)?.[propToCheck] !== undefined).includes(false);
};

/**
 * Returns mapped Map
 * @param {Map<K, V> | ReadonlyMap<K, V>} map
 * @param {(object: [K, V]) => [K, V]} mapperFunction
 * @return {Map<K, V> | ReadonlyMap<K, V>}
 */
export const mapMap = <K = any, V = any>(
	map: Map<K, V> | ReadonlyMap<K, V>,
	mapperFunction: (object: [K, V]) => [K, V],
): Map<K, V> | ReadonlyMap<K, V> => {
	return new Map(Array.from(map).map(mapperFunction));
};

/**
 * Return array of specified length
 * @param {number} length
 * @returns {number[]}
 */
export const arrayOf = (length: number) => Array.from(Array(length).keys());

/**
 * Returns shortened text (if had to be shortened) with three dots at the end
 * @param {string} text
 * @param {number} max
 * @returns {string}
 */
export const shortenText = (text: string, max: number = 21): string => {
	return text.substr(0, max) + (text.length > max ? '...' : '');
};

/**
 * Returns full image url
 * @param {string} filename
 * @param {string} path
 * @return {string}
 */
export const imageFromFilename = (filename: string, path: string = 'organizers'): string => `${process.env.REACT_APP_IMG_URL}/${path}/${filename}`;

/**
 * Formats date range
 * @param {Date | string} _from
 * @param {Date | string} _to
 * @return {string}
 */
export const formatDateRange = (_from: Date | string, _to: Date | string): string => {
	const from = moment(_from).utc();
	const to = moment(_to).utc();

	return from.isSame(to, 'day') && from.isSame(to, 'month') && from.isSame(to, 'year')
		? `${from.format('D. M. YYYY')}`
		: from.isSame(to, 'month') && from.isSame(to, 'year')
		? `${from.format('D. M.')} - ${to.format('D. M. YYYY')}`
		: `${from.format('D. M. YYYY')} - ${to.format('D. M. YYYY')}`;
};

/**
 * Returns random date within provided range
 * @param {Date} start
 * @param {Date} end
 * @return {Date}
 */
export const randomDate = (start: Date, end: Date): Date => {
	return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
export const randomInt = (min: number, max: number): number => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

/**
 * Formats czech bank account number
 * @param {string} number
 * @param {string} bank
 * @param {string} prefix
 * @return {string}
 */
export const formatCzechBankAccount = (number: string, bank: string, prefix?: string): string => {
	if (isEmpty(prefix)) {
		return `${number}/${bank}`;
	}
	return `${prefix}-${number}/${bank}`;
};

/**
 * Silences error and returns fallback value if error is thrown
 * @param {(...args: any) => R} func
 * @param {F} fallback
 * @returns {R | F}
 */
export const silenceError = <R, F = undefined>(func: (...args: any) => R, fallback?: F): R | F => {
	try {
		return func();
	} catch (e) {
		return fallback as F;
	}
};

const CURRENT_API_STAGE = process.env.REACT_APP_STAGE === 'production' ? 'production' : process.env.REACT_APP_STAGE === 'beta' ? 'beta' : 'staging';

/**
 * Returns correct path to NFCtron Tickets app
 * @param url The URL to append to the base path or a URL builder function
 * @param stage The API stage to use, defaults to the current API stage
 * @returns The an URL object to NFCtron Tickets app
 */
export const getTicketsUrl = (url: string | UrlBuilder = '/', stage: string | undefined = CURRENT_API_STAGE) => {
	const basePath = calcValue(() => {
		switch (stage) {
			case 'production':
				return 'https://tickets.nfctron.com';
			case 'beta':
				return 'https://beta.tickets.nfctron.com';
			case 'staging':
				return 'https://dev.tickets.nfctron.com';
			default:
				return 'https://dev.tickets.nfctron.com';
		}
	});
	return typeof url === 'function' ? createUrlWithBuilder(url, basePath) : new URL(url, basePath);
};

/**
 * Simple IIFE to calculate value from getter
 * @param getter Getter function
 * @returns Value from the getter
 */
export const calcValue = <T>(getter: () => T) => getter();

/**
 * Creates URL object from string or URL builder function
 * @param url The URL as string or URL builder function
 * @param basePath The base path to use
 * @returns The URL object
 */
export const createUrlWithBuilder = (url: UrlBuilder, basePath: string | URL) => url(new URL(basePath.toString()));
export type UrlBuilder = (url: URL) => URL;
